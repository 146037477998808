import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { BreakpointProvider } from 'react-socks'
import { Flex } from '@rebass/grid'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import Navbar from '../components/Navbar'
import Footer from '../components/footer'
import { OutlineButton } from '../components/buttons'

const NotFoundPage = props => {
  const { bgImage } = useStaticQuery(
    graphql`
      query {
        bgImage: file(relativePath: { eq: "404/chat.png" }) {
          childImageSharp {
            fixed(width: 424) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  const goHome = () => (window.location = '/')
  const goContact = () => (window.location = '/contact')
  return (
    <BreakpointProvider>
      <Flex
        flex="1 1 auto"
        flexDirection="column"
        css={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Helmet
          script={[
            {
              type: 'text/javascript',
              innerHTML: `${props.pageContext.autopilot}`,
            },
          ]}
        >
          <title>Hubtype - Page not found</title>
          <style type="text/css">{`
        html, body, #___gatsby, #___gatsby>div {
            min-height: 100%;
            width: 100%;
        }
    `}</style>
        </Helmet>
        <Navbar
          theme="dark"
          style={{
            background: 'linear-gradient(180deg, #333333 0%, #6500CB 1266.15%)',
            borderBottom: 'none',
          }}
          {...props}
        />
        <Flex
          flex="1 1 auto"
          alignItems="center"
          justifyContent="center"
          css={{
            backgroundColor: '#362745',
            minHeight: 600,
          }}
        >
          <Flex flexDirection="column" alignItems="center">
            <Img
              fixed={bgImage.childImageSharp.fixed}
              style={{ marginBottom: 40 }}
            />
            <Flex alignItems="center" pl={20}>
              <OutlineButton
                color="white"
                hoverBackground="#FF4B76"
                style={{
                  background: '#FF2B5E',
                  marginRight: 40,
                  height: '40px',
                  maxWidth: '140px',
                }}
                onClick={goHome}
              >
                GO TO HOME
              </OutlineButton>
              <OutlineButton
                color="white"
                hoverBackground="#FF4B76"
                style={{
                  background: 'transparent',
                  height: '40px',
                  maxWidth: '140px',
                }}
                onClick={goContact}
              >
                CONTACT US
              </OutlineButton>
            </Flex>
          </Flex>
        </Flex>
        <Footer {...props} pageContext={{ lang: 'en_US' }} />
      </Flex>
    </BreakpointProvider>
  )
}
export default NotFoundPage
